* {
	margin: 0;
	padding: 0;

	-webkit-tap-highlight-color: rgba(0,0,0,0);
	-webkit-tap-highlight-color: transparent;

	@include antialiased;
}

*,
*:after,
*:before {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

*:focus {
	outline-color: transparent;
	outline-style: none;
}

::selection {
	background: $selection-bg;
	color: $selection-color;
	text-shadow: none;
}

::-webkit-selection {
	background: $selection-bg;
	color: $selection-color;
	text-shadow: none;
}

::-moz-selection {
	background: $selection-bg;
	color: $selection-color;
	text-shadow: none;
}

html {
	font-family: $font-base;
}

body,
html {
	font-family: $font-base;
	width: 100%;
	min-height: 100%;
	min-width: $site-min-width;
}

body {
	overflow-x: hidden;
	text-align: $text-align;
	background-color: $color-bg;

	&[data-fadein] {
		> nav,
		> main,
		> footer {
			display: none;
		}
	}

	&.fixed,
	&.nav-active {
		overflow: hidden;
	}

	div.container {
		max-width: $site-max-width;
		margin: 0 auto;
		//padding: 0 $site-padding;


		@include responsive(desktop) {
			padding: 0 $site-padding-desktop;
		}

		@include responsive(tablet) {
			padding: 0 $site-padding-tablet;
			overflow-x: hidden;
		}

		@include responsive(mobile) {
			padding: 0 $site-padding-mobile;
		}

	}
}

article,
footer,
header,
section {
	position: relative;
	width: 100%;
	float: left;
}

img {
	display: block;
	vertical-align: middle;
	max-width: 100%;
	border: none;
}

a {
	border: none;
	cursor: pointer;
	text-decoration: none;

	&:focus,
	&:hover,
	&:active {
		outline: 0;
	}
}

ul {
	list-style-type: none;
}
