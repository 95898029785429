main.profile {
    section.hero {
        position: relative;
        width: 100%;
        height: 590px;

        div.image {
            width: 100%;
            height: 100%;
            background-position: center 20%;
        }

        div.centered {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            right: 0;
            z-index: 9;

            h1 {
                display: block;
                color: $color-bg;
                font-size: 48px;
                letter-spacing: 4px;
                text-transform: uppercase;
                margin-bottom: 20px;
            }

            p {
                font-size: 38px;
                color: $color-bg;
                text-transform: uppercase;
                font-weight: normal;
                letter-spacing: 1px;
                line-height: 1;
            }
        }

        @include responsive(tablet) {

        }

        @include responsive(mobile) {

        }

        @include responsive(small,true) {
            height: 100vh;

            div.centered {
                padding-right: 30px;
                padding-left: 30px;

                h1 {
                    font-size: 30px;
                    margin-bottom: 15px;
                }

                p {
                    font-size: 23px;
                }
            }
        }
    }

    section.intro {
        div.container {
            @include clear;
            padding: 105px 142px 65px;

            h3 {
                font-size: 34px;
                font-family: $font-alt;
                text-align: center;
            }
        }

        @include responsive(tablet) {

        }

        @include responsive(mobile) {

        }

        @include responsive(small,true) {
            div.container {
                padding: 40px 30px;

                h3 {
                    font-size: 27px;
                    margin-bottom: 0;

                    p {
                        font-size: 27px;
                        margin-bottom: 0;
                    }
                }
            }

        }
    }

    section.video {
        div.container {
            @include clear;
            div.video {
                width: 100%;
                height: 720px;

            }

            > a {
                position: relative;
                display: inline-block;
                float: left;
                padding-top: 30px;
                font-size: 24px;
                font-weight: 500;
                padding-left: 38px;

                &:after {
                    content: '\E007';
                    font-family: $font-icon;
                    font-size: 27px;
                    position: absolute;
                    left: 0;
                }
            }
        }

        @include responsive(tablet) {

        }

        @include responsive(mobile) {

        }
        @include responsive(small,true) {
            div.container {
                padding: 0;

                > a {
                    display: none;
                }

                div.video {
                    height: 265px;

                    > a {
                        &:after {
                            line-height: 265px;
                        }
                    }
                }

                div.image {
                    &:after {
                        line-height: 265px;
                        font-size: 80px;
                    }
                }
            }
            div.share {
                margin-right: 30px;
            }
        }


    }

    section.content {
        div.container {
            @include clear;
            padding: 45px 142px 10px;

            p {
                margin-bottom: 35px;
                font-weight: 500;
                text-align: center;
            }

            h2 {
                text-align: center;
                margin-bottom: 60px;
                letter-spacing: 1px;
                line-height: 1.3;
                font-size: 34px;
            }

            h3 {
                font-family: $font-alt;
                font-size: 34px;
                text-align: center;
                padding-bottom: 10px;
                line-height: 1.2;
            }

            hr {
                @include clear;
                margin: 60px auto;

                &.second {
                    margin-top: 50px;
                }
            }

            > a.mobile {
                    position: relative;
                    display: none;
                    font-size: 24px;
                    font-weight: 500;
                    padding-left: 38px;
                    text-align: center;
                    margin: 0 auto;

                    &:after {
                        content: '\E007';
                        font-family: $font-icon;
                        font-size: 27px;
                        position: absolute;
                        left: 0;
                    }
            }

            > div {
                clear: both;
                display: table;
                margin: 0 auto;

                ul {
                    display: table;
                    margin: 0 auto 60px;

                    li {
                        display: inline-block;
                        float: left;
                        margin-right: 10px;
                        min-width: 270px;

                        &.mobile {
                            display: none;
                        }

                        &:last-child {
                            margin-right: 0;
                        }

                        a {
                        	display: table;
                            letter-spacing: 1px;
                            width: 100%;
                        }
                    }

                }


            }
        }
        @include responsive(tablet) {

        }

        @include responsive(mobile,true) {
            div.container {
                padding: 50px 80px;
            }
        }

        @include responsive(small,true) {
            div.container {
                padding: 50px 30px;

                > a.mobile {
                    display: table;
                }

                hr {
                    width: 100%;
                    margin: 40px auto;

                    &.second {
                        display: none;
                    }
                }


                > h2 ,
                > h3 {
                    display: none;
                }

                > div {
                    display: none;
                }
            }
        }
    }

    section.blocks {
        div.container {
            @include clear;
            padding-bottom: 85px;

            ul {
                li {
                    @include transition(all,1000ms,ease);
                    position: relative;
                    width: 50%;
                    height: 360px;
                    float: left;

                    a {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        z-index: 2;

                    }

                    &:hover {
                        @include gray(100%);

                        p {

                            span {
                                &:first-child {
                                    opacity: 0;
                                    visibility: hidden;
                                }
                                &:last-child {
                                    opacity: 1;
                                    visibility: visible;
                                }
                            }

                        }
                    }

                    p {
                        display: block;
                        position: relative;

                        span {
                            @include transition(all,300ms,ease);
                            font-size: 27px;
                            font-weight: bold;
                            text-transform: uppercase;
                            margin-bottom: 0;
                            letter-spacing: 2px;
                            color: $color-bg;
                            position: absolute;
                            left: 0;
                            right: 0;

                            &:last-child {
                                opacity: 0;
                                visibility: hidden;
                            }
                        }

                    }
                }
            }

            > div.mobile {
                display: none;
            }
        }
        @include responsive(tablet) {
            div.container {
                ul {
                    li {
                        height: 28vw;
                    }
                }
            }
        }

        @include responsive(mobile) {

        }

        @include responsive(small,true) {
            div.container {
                display: block;
                padding: 0 0 30px;

                ul {
                    li {
                        display: none;

                        &:first-child {
                            display: block;
                            width: 100%;
                            height: 70vw;

                            div.centered {
                                display: none;
                            }
                        }
                    }
                }

                > div.mobile {
                    clear: both;
                    display: block;
                    padding: 45px 0 0;

                    h2 ,
                    h3 {
                        padding-left: 30px;
                        padding-right: 30px;
                    }

                    h2 {
                        font-size: 24px;
                        text-align: center;
                        margin-bottom: 45px;
                        text-transform: uppercase;
                        letter-spacing: 1px;
                        line-height: 1.3;
                    }

                    h3 {
                        font-family: $font-alt;
                        font-style: italic;
                        font-size: 28px;
                        text-align: center;
                        line-height: 1.3;
                    }

                    > div {
                        clear: both;
                        display: table;
                        margin: 30px auto 10px;

                        > a {
                            display: block;
                            margin-bottom: 10px;
                            //margin-right: 10px;

                            &:last-child {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}
