div.share {
    @include clear;
    padding-top: 30px;
    display: inline-block;
    float: right;

    ul {
        float: right;

        li {
            position: relative;
            float: left;
            width: 100px;
            height: 30px;
            text-align: center;

            a {
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
            }

            &:after {
                font-family: $font-icon;
                font-size: 22px;
                color: $color-bg;
                text-align: center;
                line-height: 30px;
            }

            &:first-child {
                background-color: #3b5998;
                margin-right: 10px;

                &:after {
                    content: '\E002';
                }
            }

            &:last-child {
                background-color: #4099ff;

                &:after {
                    content: '\E003';
                }
            }
        }
    }

    @include responsive(tablet) {

    }

    @include responsive(mobile) {

    }

    @include responsive(small) {
        display: none;
    }
}
