
div.ninja-forms-error {
	input,
	textarea,
	select {
		background: $color-body !important;
		color: #fff !important;

		@include placeholder {
			color: #fff !important;
		}
	}
}

div.ninja-forms-success-msg p {
	color: #fff;
	max-width: 400px;
    margin: 40px auto 0;
}

div.ninja-forms-field-error,
div.ninja-forms-error-msg {
	display: none !important;
}

.ninja-forms-req-symbol ,
.nf-error-msg {
	color: $color-body;
	width: 100%;
	clear: both;
	margin-bottom: 5px;
	font-weight: 500;
}

.nf-field-container {
	margin-bottom: 18px !important;
}

div.nf-error input {
	border: 2px solid $color-red !important;
}

div.nf-error div.nf-field-element {
	&:after {
		border: 2px solid $color-red !important;
	}
}

div.nf-error.active div.nf-field-element {
	&:after {
		border: 2px solid $color-body !important;
	}
}

.nf-error-msg {
	color: $color-body !important;
}

.nf-response-msg {
	padding-bottom: 10px;
	span {
		font-size: 24px !important;
	}
}
