main.home {
    section.hero {
        width: 100%;
        height: calc(100vh - 64px);
        position: relative;

        div.image {
            width: 100%;
            height: 100%;
        }

        div.centered {
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            z-index: 10;

            center {
                img {
                    margin: 0 auto;
                }
                h1 {
                    margin-bottom: 75px;
                }
            }
        }

        @include responsive(tablet) {

        }

        @include responsive(mobile) {
            height: calc(100vh - 64px);

            div.centered {
                padding: 0 30px;
            }

        }

        @include responsive(small) {
            height: calc(100vh - 75px);

            div.centered {
                width: calc(100% - 60px);
                margin: 0 auto;
                right: 0;

                center {
                    h1 {
                        margin-bottom: 30px;
                    }
                    > img {
                        width: 140px;
                    }
                }
            }
        }
    }

    section.intro {
        div.container {
            @include clear;
            padding: 105px 142px;

            h3 {
                font-family: $font-alt;
                text-align: center;
                font-size: 45px;
                margin-bottom: 0;
                line-height: 1;
            }
        }

        @include responsive(tablet) {

        }

        @include responsive(mobile) {

        }

        @include responsive(small,true) {
            div.container {
                padding: 40px 30px;

                h3 {
                    font-size: 27px;
                }
            }
        }
    }

    section.video {
        div.container {
            @include clear;

            div.video {
                width: 100%;
                height: 720px;
                overflow: hidden;

                div.image {
                    @include transition(all , 1000ms, ease );
                    width: 100%;
                    height: 100%;
                    position: relative;
                    z-index: 99;
                    top: 0;
                    left: 0;

                    &:after {
                        content: '\E006';
            			font-family: $font-icon;
            			font-size: 110px;
            			color: $color-bg;
            			position: absolute;
            			z-index: 99;
            			opacity: 0.5;
            			left: 0;
            			right: 0;
            			text-align: center;
            			line-height: 720px;
                    }

                }

                video {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        @include responsive(tablet) {

        }

        @include responsive(mobile) {

        }

        @include responsive(small) {
            div.container {
                padding-left: 0;
                padding-right: 0;
                div.video {
                    height: 235px;

                    div.image {
                        &:after {
                            font-size: 65px;
                            line-height: 235px;
                        }
                    }
                }
            }
        }
    }

    section.content {
        div.container {
            @include clear;
            padding: 60px 142px 75px;

            p {
                padding-left: 40px;
                padding-right: 40px;
                margin-bottom: 32px;
                font-weight: 500;
                text-align: center;
            }

            hr {
                margin: 60px auto 55px;
            }

            h2 {
                text-align: center;
                margin-bottom: 50px;
                letter-spacing: 3px;
                text-transform: uppercase;
            }

            > div {
                width: 100%;

                ul {
                    display: table;
                    margin: 0 auto;

                    li {
                        display: inline-block;
                        float: left;
                        margin-right: 10px;

                        &.mobile {
                            display: none;
                        }

                        &:last-child {
                            margin-right: 0;
                        }

                        a {
                        	display: table;
                            letter-spacing: 1px;
                        }
                    }

                }
            }



        }
        @include responsive(tablet) {

        }

        @include responsive(mobile,true) {
            div.container {
                p {
                    padding-left: 0;
                    padding-right: 0;
                }
            }
        }

        @include responsive(mobile) {
            div.container {
                padding: 60px 60px 75px;

                p {
                    padding: 0;
                }
            }
        }

        @include responsive(small,true) {
            div.container {
                h2 {
                    font-size: 30px;
                }
            }
        }

        @include responsive(small) {
            div.container {
                padding: 50px 0 0;
                p , h2 {
                    padding: 0 30px;
                }

                > div {
                    width: 100%;

                    ul {
                        width: 100%;
                        li {
                            width: 100%;
                            float: none;
                            background-color: #363636;
                            margin-bottom: 3px;
                            height: 106px;

                            &.mobile {
                                display: block;
                            }

                            a {
                                position: relative;
                                display: block;
                                border: none;
                                color: $color-bg;
                                line-height: 106px;
                                padding: 0;
                                font-size: 21px;

                                &:after {
                                    content: '\E005';
                                    font-family: $font-icon;
                                    color: #95989a;
                                    font-size: 25px;
                                    position: absolute;
                                    right: 30px;
                                }

                                &:hover {
                                    background-color: inherit;
                                    color: $color-bg;
                                }
                            }
                        }
                    }
                }
            }
        }

        @media (max-width: 400px) {
            div.container {
                h2 {
                    font-size: 26px;
                }
            }
        }

        @media (max-width: 370px) {
            div.container {
                h2 {
                    font-size: 24px;
                    letter-spacing: 2px;
                }
            }
        }
    }
}
