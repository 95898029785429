section.people {
    display: none;
    @include responsive(tablet) {

    }

    @include responsive(mobile) {
        display: block;

        div.container {
            padding: 0;

            ul {
                li {
                    margin-bottom: 15px;
                    position: relative;

                    > a {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        z-index: 99;
                        top: 0;
                    }
                    > div.image {
                        height: calc(100vh - 75px - 110px);
                    }

                    div.content {
                        //padding: 30px 30px 0;
                        width: 100%;
                        padding-left: 30px;
                        padding-right: 30px;
                        height: 110px;
                        position: relative;
                        display: table;

                        center {
                            height: 100%;
                            display: table-cell;
                            vertical-align: middle;
                            h4 {
                                font-size: 27px;
                                text-transform: uppercase;
                                text-align: left;

                                &:last-child {
                                    font-weight: 500;
                                }
                            }
                        }



                        &:after {
                            content: '\E005';
                            font-family: $font-icon;
                            font-size: 35px;
                            color: #95989a;
                            display: table-cell;
                            vertical-align: middle;
                            text-align: right;
                        }
                    }
                }
            }
        }
    }

    @include responsive(small) {
        div.container {

            ul {
                li {
                    height: calc(100vh - 75px);
                    margin-bottom: 0;
                }
            }
        }
    }
}
