.lity {
	background: rgba(0,0,0,0.7);
}

div.lity-container {

	video {
		width: 100% !important;
		height: 100%;
		object-fit: cover;
	}
}
video {
	width: 100% !important;
	height: 100%;
	object-fit: cover;
}
