html,
body,
button,
input,
select,
textarea {
	font-family: $font-main;
	font-size: $font-size;
	font-weight: $font-weight;
	color: $color-body;
}


body {

}

main {
	display: block;
	margin-top: 66px;
	// float: left;
	// width: 100%;

	@media(max-width: 768px) {
		margin-top: 75px;
	}
}

h1,
h2,
h3 {
	color: $color-body;
	font-family: $font-main;
	line-height: 1.1;
	font-weight: bold;
	margin-bottom: 25px;

	@include responsive(mobile) {

	}
}

h1 a,
h2 a,
h3 a {
	color: $color-body;
	text-decoration: none;

	&:hover {
		color: $color-light;
		text-decoration: none;
	}
}

h1 {
	font-size: 35px;
	@include responsive(mobile) {

	}
}

h2 {
	font-size: 35px;

	@include responsive(mobile) {

	}

	@include responsive(small) {
		font-size: 25px;
		line-height: 1.3;
	}
}

h3 {
	font-size: 45px;

	@include responsive(mobile) {

	}
}

h4 {
	font-size: 30px;

	@include responsive(mobile) {

	}
}

p {
	font-size: 23px;
	margin-bottom: 25px;
	line-height: 1.4;
	font-weight: normal;

	@include responsive(mobile) {

	}

	@include responsive(small) {
		font-size: 19px;
	}

	strong {
		font-weight: bold;
	}

	a {
		text-decoration: none;
		//color: $color-light;

		&:hover,
		&.active {
			color: $color-body;
		}
	}
}

a {
	color: $color-body;
	text-decoration: none;

	&:hover,
	&.active {
		color: $color-body;
		@include transition(all, $transition, ease);
	}
}

sup {
	font-size: 50%;
}

.image {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
}

div.centered {
	display: table;
	width: 100%;
	height: 100%;

	center {
		position: relative;
		height: 100%;
		width: 100%;
		display: table-cell;
		vertical-align: middle;
		text-align: center;
	}
}

div.video {
	position: relative;

	video {
		position: absolute;
	    object-fit: cover;
		max-width: 100%;
		min-width: 100%;
		max-height: 100%;
		min-height: 100%;
		top: 0;
		left: 0;
		right: 0;
	}
}

a.play {
	position: absolute;
	width: 100%;
	height: 100%;

	&:after {
		content: '\E006';
		font-family: $font-icon;
		font-size: 110px;
		color: $color-bg;
		position: absolute;
		z-index: 99;
		opacity: 0.76;
		left: 0;
		right: 0;
		text-align: center;
		line-height: 720px;

	}
}

a {
	// @include transition(all,400ms,ease);
	// display: table;
	// color: $color-red;
	// font-size: 26px;
	// border: 2px solid $color-red;
	// text-align: center;
	// padding: 10px 24px;
	// text-transform: uppercase;
	// margin: 26px auto 0;
	//
	// &:hover {
	// 	background-color: $color-red;
	// 	color: $color-bg;
	// }
}

div.video {
	width: 100%;
	height: 720px;
	overflow: hidden;

	div.image {
		@include transition(all , 1000ms, ease );
		width: 100%;
		height: 100%;
		position: relative;
		z-index: 99;
		top: 0;
		left: 0;

		&:after {
			content: '\E006';
			font-family: $font-icon;
			font-size: 110px;
			color: $color-bg;
			position: absolute;
			z-index: 99;
			opacity: 0.5;
			left: 0;
			right: 0;
			text-align: center;
			line-height: 720px;
		}

	}

	video {
		width: 100%;
		height: 100%;
	}

}
.button-link {
	@include transition(all,400ms,ease);
	padding: 10px 24px;
	border: 3px solid $color-red;
	color: $color-red;
	font-size: 26px;
	text-transform: uppercase;
	line-height: 1;
	text-align: center;
	margin: 0 auto;

	&:hover {
		background-color: $color-red;
		color: $color-bg;
	}
}

hr {
	width: 180px;
	margin: 0 auto;
}
