/********** MIXINS **********/

@mixin responsive($media, $fixed: false, $orientation: false) {
	@if $orientation {
		@if $media == mobile {
			@media only screen and (min-width: $mobile) and (max-width: $tablet - 1) and (orientation:#{$orientation}) { @content; }
		}
		@else if $media == tablet {
			@media only screen and (min-width: $tablet) and (max-width: $desktop) and (orientation:#{$orientation}) { @content; }
		}
	} @else if $fixed {
		@if $media == small {
			@media only screen and (max-width: $mobile - 1) { @content; }
		}
		@if $media == mobile {
			@media only screen and (min-width: $mobile) and (max-width: $tablet - 1) { @content; }
		}
		@else if $media == tablet {
			@media only screen and (min-width: $tablet) and (max-width: $desktop - 1) { @content; }
		}
		@else if $media == desktop {
			@media only screen and (min-width: $desktop) and (max-width: $large - 1) { @content; }
		}
	} @else {
		@if $media == small {
			@media only screen and (max-width: $small) { @content; }
		}
		@if $media == mobile {
			@media only screen and (max-width: $mobile) { @content; }
		}
		@else if $media == tablet {
			@media only screen and (max-width: $tablet) { @content; }
		}
		@else if $media == desktop {
			@media only screen and (max-width: $desktop - 1) { @content; }
		}
	}
}// @include responsive(mobile, landscape) { float:left; };


@mixin transition($on: all, $duration: 300ms, $method: ease) {
	-webkit-transition: $on $duration $method;
	-moz-transition: $on $duration $method;
	-ms-transition: $on $duration $method;
	-o-transition: $on $duration $method;
	transition: $on $duration $method;
}// @include transition(all, 300ms, ease);


@mixin no-select() {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}// @include no-select;


@mixin rotate($amount: 90deg) {
	-webkit-transform: rotate($amount);
	-moz-transform: rotate($amount);
	transform: rotate($amount);
}// @include rotate(180deg);


@mixin scale($ratio: 1.1) {
	-webkit-transform: scale($ratio);
	-moz-transform: scale($ratio);
	transform: scale($ratio);
}


@mixin clear() {
	&:after {
		content: '';
		display: block;
	    clear: both;
	    *zoom: 1;
	}
}// @include clear;

@mixin box-shadow($left:0, $top:2px, $blur:2px, $color:#000, $inset:"") {
	-webkit-box-shadow:$left $top $blur $color #{$inset};
	-moz-box-shadow:$left $top $blur $color #{$inset};
	box-shadow:$left $top $blur $color #{$inset};
}// @include box-shadow;


@mixin columns($count:2, $gap:20px) {
	  -webkit-columns: $count;
	     -moz-columns: $count;
	          columns: $count;
	  -webkit-column-gap: $gap;
	     -moz-column-gap: $gap;
	          column-gap: $gap;
}// @include columns;

@mixin fill($top:0, $right:0, $bottom:0, $left:0) {
	position: absolute;
	top: $top;
	right: $right;
	left: $left;
	bottom: $bottom;
}// @include fill;

@mixin reset($postion: relative) {
	position: $postion;
	top: inherit;
	bottom: inherit;
	left: inherit;
	right: inherit;
	z-index: inherit;
}// @include reset;

@mixin centered() {
	display: table;
	table-layout: fixed;
	text-align: center;
	height: 100%;
	width: 100%;

	center {
		display: table-cell;
		vertical-align: middle;
	}
}

@mixin cover($align:center) {
	background-size: cover;
	background-position: $align;
	background-repeat: no-repeat;
}// @include bgcover;

@mixin ellipsis ($max-width){
	display: inline-block;
	max-width: $max-width;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}// @include ellipsis;

@mixin gray($amout:100%){
	-webkit-filter: grayscale($amout);
	-moz-filter: grayscale($amout);
	-ms-filter: grayscale($amout);
	-o-filter: grayscale($amout);
	filter: grayscale($amout);
}// @include gray;

@mixin antialiased(){
	-webkit-font-smoothing: antialiased;
	font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
	-moz-osx-font-smoothing: grayscale;
}// @include antialiased;


@mixin placeholder {
	&::-webkit-input-placeholder {@content}
	&:-moz-placeholder           {@content}
	&::-moz-placeholder          {@content}
	&:-ms-input-placeholder      {@content}
}
