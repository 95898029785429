header {
    background-color: $color-body;
    position: fixed;
    z-index: 99999;
    top: 0;

    div.container {
        @include clear;
        padding-left: 142px;
        padding-right: 142px;
        padding-bottom: 2px;

        a.logo {
            float: left;
            width: 150px;

            img {

            }
        }

        a.video {
            display: none;
            float: right;

            &:after {
                content: '\E00C';
                font-family: $font-icon;
                color: $color-bg;
                font-size: 30px;
                position: absolute;
                top: 23px;
                right: 20px;
            }
        }

        a.nav {
    		display: none;
    		position: absolute;
    		top: 26px;
    		left: 20px;
    		height: 28px;
    		cursor: pointer;
    		text-align: center;
    		z-index: 2;
    		@include no-select;
    		@include transition(all, 300ms, ease);

    		.burger-menu {
    			width: 30px;
    			height: 24px;
    			position: relative;
    		}

    		.burger-menu__line {
    			width: 100%;
    			height: 3px;
    			background-color: $color-bg;
    			position: absolute;
    			left: 0;
    			top: 0;
    			@include transition(all, 300ms, ease);
    		}

    		.dark .burger-menu__line {
    			background-color: #fff;
    		}

    		.burger-menu__line:first-child + .burger-menu__line {
    			top: 50%;
    			margin-top: -1.5px;
    		}

    		.burger-menu__line:first-child + .burger-menu__line + .burger-menu__line {
    			top: auto;
    			bottom: 0;
    		}

    		&.active {
    			.burger-menu__line {
    				top: 50%;
    				margin-top: -1.5px;
    				bottom: auto;
    				@include rotate(135deg);
    			}

    			.burger-menu__line:first-child + .burger-menu__line + .burger-menu__line {
    				top: 50%;
    				margin-top: -1.5px;
    				bottom: auto;
    				@include rotate(45deg);
    			}
    		}
    	}

        nav.main {
            float: right;
            margin-top: 2px;

            ul {
                margin: 0 -25px;
                li {
                    float: left;
                    margin: 0 25px;

                    &:last-child {
                        margin-right: 0;
                    }

                    a {
                        @include transition(all , 400ms , ease);
                        color: $color-bg;
                        font-size: 19px;
                        font-weight: bold;
                        line-height: 64px;
                        text-transform: uppercase;
                        letter-spacing: 1px;

                        &:hover {
                            color: #c2c2c2;
                        }
                    }

                    &#contact-us {
                        display: none;
                    }

                    &.active {
                        a {
                            color: #c2c2c2;
                        }
                    }
                }
            }
        }
    }

    @media(max-width: 1300px) {
        div.container {
            padding: 0 50px;
        }
    }

    @include responsive(tablet) {
        div.container {
            padding: 0 30px;
            nav.main {
                ul {
                    margin: 0 -15px;
                    li {
                        margin: 0 15px;
                    }
                }
            }
        }
    }

    @media(max-width: 1023px) {
        div.container {
            padding: 0 30px;
            nav.main {
                ul {
                    margin: 0 -15px;
                    li {
                        margin: 0 12px;

                        a {
                            font-size:16px;
                        }
                    }
                }
            }
        }
    }

    @include responsive(mobile) {
        position: fixed;
        top: 0;
        z-index: 99999;

        div.container {
            padding: 10px 0;
            a.nav {
                display: block;

    			.burger-menu {
    				width: 30px;
    				height: 21px;
    			}
		    }

            a.nav {
                top: 29px;
            }
            a.logo {
                display: table;
                margin: 0 auto;
                text-align: center;
                float: none;
                width: 135px;

                img {
                    width: 135px;
                    margin: 0 !important;
                }
            }

            a.video {
                display: block;

                &:after {
                    top: 23px;
                }
            }

            nav.main {
                width: 100%;
                height: 100%;
                position: fixed;
                top: 64px;
                left: 0;
                z-index: 99999;
                background-color: $color-body;
                padding: 20px 0;
                opacity: 0;
                visibility: hidden;
                max-height: 0;

                ul {
                    border-top: 2px solid #464646;
                    li {
                        float: none;
                        display: block;
                        text-align: center;
                        border-bottom: 2px solid #464646;
                    }
                }
            }
        }
    }

    @include responsive(small) {
        height: 75px;

        div.container {
            padding: 0 20px;

            a.video {
                &:after {
                    top: 23px;
                }
            }

            a.nav {
                top: 26px;
            }

            a.logo {
                margin-top: 15px;
                width: 109px;

                img {
                    width: 109px;
                }
            }
        }
    }
}

/* MOBILE NAVIGATION */
nav.mobile {
    @include transition(all , 600ms , ease);
    opacity: 0;
    visibility: hidden;
    background-color: #292929;
    position: fixed;
    top: 75px;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    overflow-y: auto;
    z-index: 9999;

    ul {
        border-top: 2px solid #464646;

        li {
            height: calc((100vh - 80px) / 6);
            text-align: center;
            border-bottom: 2px solid #464646;

            &:last-child {
                border-bottom: none;
            }

            a {
                color: $color-bg;
                font-size: 40px;
                font-weight: 500;
                line-height: calc((100vh - 80px) / 6);
                text-align: center;
                text-transform: uppercase;
            }
        }
    }

    @include responsive(mobile) {
        top: 64px;

        &.active {
            opacity: 1;
            visibility: visible;
        }
    }

    @include responsive(small) {
        top: 75px;
    }

}
