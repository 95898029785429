.gm-bundled-control-on-bottom {
    .gmnoprint {
        border:1px solid #fff;

        div {
            background:#fff !important;
        }
    }
}

main.stockists {

    .entriesBlock {
        float:left;
        width:100%;
        overflow: auto;
        height:calc(100% - 172px);
        font-size:15px;
        position: relative;
        background:#c41230;
        color:#fff;
        font-weight: 500;
        opacity: 0;
        display:none;

        &.active {
            opacity: 1;
        }

        .stockists {
            .entry {
                a.number {
                    background:#d8d8d8;
                    color:#000;
                }
            }
        }

        .baseLinks {
            float:left;
            width: 100%;
            margin-top:10px;

            span {
                float:left;
                width: 33.33%;

                &:nth-child(2) {
                    text-align: center;
                }

                &:nth-child(3) {
                    text-align: right;
                }
            }

            a {
                color: #000;
                font-size: 13px;
                font-weight: 600;
                text-transform: uppercase;

                @include transition(all, 0ms, ease);

                &:hover {
                    border-bottom: 2px solid #000;

                    @include transition(all, 0ms, ease);
                }
            }
        }

        &.base {
            clear:both;
            float:left;
            width:100%;
            height:auto;
            display: none;

            @media(max-width: 1023px) {
                display: block;
            }
        }

        @media(max-width: 1023px) {
            display:none;
        }

        .noneFound {
            display: none;
            float:left;
            width:100%;
            padding:25px 50px 25px 50px;
            position: relative;

            p {
                color: #fff !important;
                font-size: 16px !important;
                margin: 0 !important;
                text-transform: uppercase;
                font-weight: 500;
            }

            @media(max-width: 1280px) {
                padding-left:25px;
            }
        }

        .listingBlock {
            .container {
                float:left;
                width: 100%;
                clear:both;
                padding:0px !important;
            }
        }

        p {
            font-size: 15px;
            margin-bottom:10px;
            font-weight: 500;

            a {
                color:#fff;
            }

            &.title {
                color:#fff;
                margin:0px 0px 15px;
                padding:0px;
                border: 0px;

                a {
                    color:#000;
                    font-size: 20px;
                    font-family: "Interstate";
                    font-weight: 700;
                }
            }
        }

        .entry {
            float:left;
            width:100%;
            padding:25px 100px 25px 50px;
            border-bottom:1px solid #ccc;
            position: relative;
            background:#fff;
            color:#000;

            &.active {
                background:#f4f4f4;
            }

            &:hover {
                background:#f4f4f4;
            }

            @media(max-width: 1280px) {
                padding-left:25px;
            }


            /*&:nth-child(2n+1) {
                background:#a40f28;
            }*/

            span.rightPanel {
                position: absolute;
                right:20px;
                width:70px;
                text-align: center;
                top:25px;
                float:left;
                color:#000;
            }

            span.distance {
                font-size: 13px;
                float:left;
                width: 100%;
                margin-top:10px;
                clear:both;
            }

            a.number {
                height:30px;
                width:30px;
                display: inline-block;
                text-align: center;
                line-height: 30px;
                border-radius: 50%;
                background:#fff;
                font-family: "Interstate";
                font-size: 12px;
                font-weight: 700;
                background-color:#c21e2e;
                color:#fff;
                cursor: pointer;
            }

            a.website {
                color:#000;
                text-transform: uppercase;
                font-size: 13px;
                font-weight: 600;

                @include transition(all, 0ms, ease);

                &:hover {
                    border-bottom: 2px solid #000;
                    @include transition(all, 0ms, ease);
                }
            }
        }

        .title {
            float:left;
            width:100%;
            padding:25px 50px;
            cursor: pointer;
            position: relative;
            border-bottom:1px solid #a40f28;

            @media(max-width: 1280px) {
                padding-left:25px;
            }

            &.active {
                span {
                    &.arrowDown {
                        display:none;
                    }
                    &.arrowUp {
                        display:block;
                    }
                }
            }

            h2 {
                margin:0px !important;
                color:#fff !important;
                font-size: 16px !important;
            }

            span {
                position: absolute;
                right:25px;
                top:25px;
                font-size: 20px;

                &.results {

                    float:left;
                    font-size: 13px;
                    right:60px;
                    top:28px;

                    span {
                        position: relative;
                        top:0px;
                        right:0px;
                        font-size: 13px;
                    }
                }

                &.arrowUp {
                    display: none;
                }

                &.arrowDown {
                    display:block;
                }

            }
        }
    }

    .gm-style-iw {
        top:0px !important;
        left:0px !important;
        background:#c41230;
        color:#fff;
        width:100% !important;
    }

    .markerlabels {
        height:30px;
        width:30px;
        color: #fff;
        border-radius: 50%;
        text-align: center;
        line-height: 30px;
        font-family: "Interstate";
        font-size: 12px;
        font-weight: 700;
        background-color:#c21e2e;

        &.stockist {
            color:#000;
            background:#d8d8d8;
        }
    }

    .infoBox {
        padding:20px 20px 20px;
        color:#000;
        background:#fff !important;
        font-size: 15px;
        font-family: "Interstate";
        line-height: 1.2;
        position: relative;
        width:280px !important;

        p {
            font-size: 15px;
            font-family: "Interstate";
            line-height: 1.2;
            color:#000;
            margin-bottom: 10px;

            &.baseLinks {

                span {
                    float:left;
                    width: 100%;
                }

                a {
                    float:left;
                    width: auto;
                    color: #000;
                    font-size: 13px;
                    font-weight: 600;
                    text-transform: uppercase;
                    margin-bottom: 10px;
                    border-bottom: 2px solid #fff;

                    span {

                    }

                    @include transition(all, 0ms, ease);

                    &:hover {

                            border-bottom: 2px solid #000;

                            @include transition(all, 0ms, ease);


                    }
                }

            }
        }

        hr {
            border:0;
            background:#ccc;
            width:100%;
            height:1px;
            margin:20px 0px 20px;
        }

        &:after {
            content:"";
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 15px 15px 0 15px;
            border-color: #fff transparent transparent;
            position: absolute;
            bottom:-15px;
            left:50%;
            margin-left:-10px;
        }

        strong {
            text-transform: uppercase;
            font-family: "Interstate";
            font-size: 20px;
            font-weight: 700;
            line-height:1.2;
            margin-bottom: 15px;
            display: block;
        }

        .close {
            font-size: 20px;
            line-height: 20px;
            position: absolute;
            right: -15px;
            top: -15px;
            color:#fff;
            border-radius: 50%;
            width:30px;
            height:30px;
            text-align: center;
            line-height: 29px;
            background:#c21e2e;
        }
    }

    h2 {
        color: #000;
        float: left;
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 10px;
        padding: 0;
        text-transform: uppercase;

        @include responsive(small,true) {

        }
    }

    h4 {
        margin-bottom: 8px;

        @include responsive(mobile) {
            font-size: 16px;
        }
    }

    section.topBlock {
        // margin-top: 66px;
        height:575px;

        @media(max-width: 1023px) {
            height:auto;
        }

        div.map {
            float: right;
            width: 60%;
            overflow:hidden;

            @include clear;
            height: 575px;

            @media(max-width: 1280px) {
                width: 57%;
            }

            @media(max-width: 1023px) {
                width: 100%;
                height: 405px;
            }

            > div {
                height: 100%;

                &.locations {
                    /*display: none;*/
                    opacity:0;

                    &.active {
                        opacity:1;
                    }

                    > div {
                        /*visibility: hidden;*/
                    }
                }
            }

            @include responsive(tablet) {

            }

            @include responsive(mobile) {

            }

            @include responsive(small) {
                height: 340px;
            }
        }

        div.filter {
            float: left;
            width: 40%;
            background-color: #c41230;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            color:#fff;
            overflow: hidden;

            @media(max-width: 1280px) {
                width: 43%;
            }

            @media(max-width: 1023px) {
                width: 100%;
                position: relative;
                height:auto;
            }


            div.container {
                @include clear;
                height:100%;
                padding:0px !important;
                /*padding: 80px 18% 100px 40%;*/

                .formBlock {
                    float:left;
                    width: 100%;
                    padding:25px 50px;
                    background:#fff;
                    position: relative;
                    height:172px;

                    @media(max-width: 1280px) {
                        padding:25px 25px;
                    }

                    @media(max-width: 479px) {
                        height:192px;
                    }
                }


                /*@media(max-width: 1250px) {
                    padding: 30px 30% 19px 25px !important;
                }*/

                h2 {
                    float: left;
                    width: 100%;
                    font-size: 16px;
                    color:#000;
                    background-color: transparent;
                    text-transform: uppercase;
                    line-height: 1.2;
                    margin-bottom: 15px;
                    text-align: left;

                    @media(max-width: 479px) {
                        margin-bottom: 10px;
                    }
                }

                .shareLink {
                    float:left;
                    width: 100%;

                    a {
                        padding-left:27px;
                        color:#000;
                        font-size: 13px;
                        text-transform: uppercase;
                        background:url(../images/share-icon.png) left center no-repeat;
                        background-size: 17px auto;

                        &:hover {
                            span {
                                border-bottom:2px solid #000;
                            }
                        }
                    }
                }

                form {

                    .errorBlock {
                        span {
                            color: $color-red;
                            font-size: 11px;
                            line-height: 15px;
                            float:left;
                            width: 100%;
                            margin-bottom: 0px;
                            position: absolute;
                            top:-17px;
                            font-weight: normal;
                            text-align: right;
                            right:0px;

                            @media(max-width: 479px) {
                                text-align: left;
                            }
                        }
                    }

                    div {
                        display: inline-block;
                        float: left;
                        width: 100%;
                        // margin-right: 30px;
                        position: relative;

                        &.storeTypes {
                            position: absolute;
                            top:0px;
                            right:0px;
                            width: auto;
                            margin-right: 0px;
                            top:25px;
                            right:35px;

                            @media(max-width: 1280px) {
                                right:10px;
                            }

                            @media(max-width: 479px) {
                                position: relative;
                                right:0px;
                                left:0px;
                                top:0px;
                            }

                            span {
                                float:left;
                                width: auto;
                                position: relative;
                            }
                        }

                        &.city {
                            margin-bottom: 15px;
                            float:left;
                            width:calc(100% - 139px);

                            @media(max-width: 479px) {
                                width:calc(100% - 86px);
                            }

                            label {
                                float: left;
                                width: 100%;
                                color: #000;
                                font-size: 16px;
                                font-weight: 700;
                                margin-bottom: 10px;
                                padding: 0;
                            }

                            input {
                                width: 100% !important;
                                padding: 8px 10px;
                                font-size: 14px;
                                border:2px solid #000;
                                height:50px !important;

                                &::placeholder {
                                    color: $color-placeholder;
                                    font-family: $font-main;
                                    font-weight: normal;
                                    line-height: 35px;
                                    font-size: 14px !important;
                                    opacity: 1;
                                }

                                &::-webkit-input-placeholder {
                                    color: $color-placeholder;
                                    font-family: $font-main;
                                    font-weight: normal;
                                    line-height: 35px;
                                    font-size: 14px !important;
                                    opacity: 1;
                                }

                                &::-moz-placeholder {
                                    color: $color-placeholder;
                                    font-family: $font-main;
                                    font-weight: normal;
                                    line-height: 35px;
                                    font-size: 14px !important;
                                    opacity: 1;
                                }

                                &:-ms-input-placeholder {
                                    color: $color-placeholder;
                                    font-family: $font-main;
                                    font-weight: normal;
                                    line-height: 35px;
                                    font-size: 14px !important;
                                    opacity: 1;
                                }
                            }
                        }

                        &.postcode {
                            display: none;
                        }

                        &.distance {
                            display: none;
                        }

                        &.storeTypes {

                            h4 {
                                float: left;
                                width: 100%;
                                color: #000;
                                font-size: 18px;
                                line-height: 20px;
                                font-weight:  700;
                                text-transform: uppercase;
                                margin-bottom: 20px;

                                @include responsive(mobile) {
                                    margin-bottom: 10px;
                                }
                            }

                            .storeSelect {
                                float: left;
                                width: 100%;

                                @include responsive(mobile) {
                                    margin-bottom: 0;
                                }

                                @media(max-width: 479px) {
                                    margin-bottom: 10px;
                                }

                                label {
                                    float: left;
                                    width: 100%;
                                    color: #000;
                                    font-size: 13px;
                                    line-height: 20px;
                                    margin-bottom: 5px;
                                    padding-left: 18px !important;
                                    position: relative;

                                    sup {
                                        position: absolute;
                                        display: inline;
                                        top:0px;
                                    }
                                }

                                input {
                                    float: left;
                                    margin-right: 10px;
                                    line-height: 20px;
                                    display: none;
                                }
                            }
                        }

                        &.region {
                            display: none;
                            position: relative;
                        }

                        &.submit {
                            margin-right: 0;
                            float:right;
                            width:124px;
                            height:50px;

                            @media(max-width: 479px) {
                                width:80px;
                            }

                            input {
                                height:50px;
                            }


                            &:hover {
                                input {
                                    background-color: $color-bg;
                                    color: $color-red !important;
                                }

                                &:after {
                                    color: $color-red !important;
                                }
                            }

                        }

                        span.arrow {
                            &:after {
                                content:'\E00A';
                                font-family: $font-icon;
                                font-size: 10px;
                                position: absolute;
                                color: #96979a;
                                top: 0;
                                right: 10px;
                                line-height: 30px;
                            }
                        }

                        label {
                            color: $color-bg;
                            float: left;
                            text-transform: uppercase;
                            font-size: 16px;
                            line-height: 30px;
                            padding-right: 15px;
                            text-align: left;
                        }

                        select , input[type="text"] {
                            float: left;
                            width: 170px;
                            height: 30px;
                            line-height: 30px;
                            cursor: pointer;

                            option {
                                color: #979a9c;
                                font-size: 15px;
                            }

                            @include placeholder() {
                                text-transform: uppercase;
                                line-height: 30px;
                            }

                            &.invalid {
                                color: $color-red;
                            }
                        }

                        select {
                            color: #8d9091;
                            position: relative;

                            &:before {
                                content: '\E00A';
                                font-family: $font-icon;
                                position: absolute;
                                right: 10px;
                                top: 20px;
                                position: absolute;
                                z-index: 9999;
                            }
                        }

                        input[type="submit"] {
                            width: 100%;
                            height: 50px;
                            line-height: 50px;
                            color: $color-bg;
                            font-size: 16px;
                            text-transform: uppercase;
                            font-weight: bold;
                            letter-spacing: 1px;
                            // padding-right: 30px;
                            text-align: center;
                            // padding-left: 12px;
                            background-color: $color-red;

                            @media(max-width: 479px) {
                                font-size: 13px;
                            }
                        }

                        &:last-child {
                            position: relative;


                            // span {
                            //
                            //     &:after {
                            //         @include transition(all,800ms,ease);
                            //         content: '\E009';
                            //         font-family: $font-icon;
                            //         font-size: 14px;
                            //         position: absolute;
                            //         color: $color-bg;
                            //         top: 0;
                            //         right: 11px;
                            //         line-height: 30px;
                            //     }
                            // }

                            &:hover {
                                input {
                                    background-color: $color-dark;
                                    color: $color-red;
                                }

                                span {
                                    &:after {
                                        color: $color-red;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            @media(max-width: 1250px) {
                /*div.container {
                    padding-left: 40px;
                    padding-right: 40px;

                    form {
                        display: table;
                        margin: 0 auto;

                        div {
                            margin-right: 15px;
                        }
                    }
                }*/
            }
            @include responsive(tablet) {

            }

            @include responsive(mobile,true) {
                // div.container {
                //     padding-left: 20px;
                //     padding-right: 20px;
                //
                //     form {
                //         div {
                //             margin-right: 5px;
                //         }
                //     }
                // }
            }

            @media(max-width: 1000px) {
                div.container {

                    form {

                        div {
                            margin-right: 5px;
                        }
                    }
                }
            }

            @media(max-width: 940px) {
                /*div.container {
                    form {
                        div {
                            width: calc(50% - 8px);
                            float: left;
                            margin-bottom: 10px;
                            input[type="text"]
                            , select ,
                            input[type="submit"] {
                                width: 90%;
                            }

                            span.arrow {
                                &:after {
                                    top: 23px;
                                    right: 12%;
                                    line-height: 40px;
                                }
                            }

                            &:nth-child(2n) {
                                margin-right: 0;
                            }

                            &:last-child {
                                //width: auto;
                                margin: 0 auto;
                            }

                            label {
                                width: 30%;
                                float: left;
                                padding: 0 !important;
                            }
                        }
                    }
                }*/
            }

            @media(max-width: 940px) {
                /*div.container {
                    padding: 30px 25px 19px;

                    form {
                        div {
                            // width: calc(50% - 10px);
                            width: 100%;
                            float: left;
                            margin: 0 5px 15px;

                            label {
                                padding-left: 15px;
                                width: 100%;
                            }

                            &.region {
                                display: block;
                            }
                            span.arrow {
                                &:after {
                                    right: 12%;
                                }
                            }

                            &:last-child {
                                //float: none;
                                //clear: both;
                                position: relative;
                                float: none;
                                margin: 0 auto;
                                display: inline-block;
                                margin-top: 18px;
                                //padding-top: 30px;

                                input {
                                    margin-top: 23px;
                                    //width: 175px;
                                    display: block;
                                    margin: 5px 5px 0;
                                    height: 40px;
                                    line-height: 40px;
                                    font-size: 18px;
                                    float: left;
                                    width: 100%;

                                }

                                span {
                                    float: left;

                                    &:after {
                                        content: '\E005';
                                        font-size: 20px;
                                        right: 14%;
                                        margin-top: 5px;
                                        line-height: 40px;
                                    }
                                }


                            }

                            label {
                                font-size: 13px;
                                font-weight: bold;
                                line-height: 1;
                                margin-bottom: 10px;
                            }

                            input[type=text] {
                                font-size: 12px;
                                height: 40px;
                                line-height: 40px;

                                @include placeholder() {
                                    font-size: 12px;
                                }
                            }

                            select {
                                font-size: 12px;
                                height: 40px;
                                line-height: 40px;
                            }

                        }
                    }
                }*/
            }

            @include responsive(small) {
                div.container {
                    form {
                        div {
                            input[type="text"] ,select {
                                width: 100%;
                            }

                            span.arrow {
                                &:after {
                                    font-size: 10px;
                                    right: 5px;
                                }
                            }

                        }
                    }
                }
            }
        }
    }

    section.shopOnline {
        height: 100px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center top;
        padding: 25px 0;
        text-align: center;

        @media(max-width: 480px) {
            height: 140px;
        }

        .container {
            position: relative;
            overflow: hidden;

            span {
                float: none;
                display: inline-block;
                color: #fff;
                font-size: 16px;
                text-transform: uppercase;
            }

            a.stdBtn {
                float: none;
                display: inline-block;
                margin-left: 50px;
                width: 270px;
                height: 50px;
                background-color: $color-red;

                @media(max-width: 480px) {
                    width: 100%;
                    margin: 10px 0 0 0;
                }

                span {
                    line-height: 51px;
                    color: #fff;
                    font-size: 16px;
                    font-weight: 700;
                }

                &:hover {
                    background-color: $color-dark;

                    span {
                        color: $color-red !important;
                    }
                }
            }
        }
    }

    section.stores {
        position: relative;

        .leftBlock {
            float: left;
            width: 40%;
            background-color: #c21e2e;
            min-height: 520px;

            @include responsive(mobile) {
                width: 100%;
            }

            .inner {
                float: left;
                width: 100%;
                padding: 50px 18% 50px 40%;

                @media(max-width: 1250px) {
                    padding: 50px 40px;
                }

                @media(max-width: 940px) {
                    padding: 30px 30% 19px 25px;
                }

                h2 {
                    float: left;
                    width: 100%;
                    color: #fff;
                    font-size: 26px;
                    line-height: 26px;
                    margin-bottom: 40px;
                    text-align: left;

                    @include responsive(mobile) {
                        background-color: transparent !important;
                    }
                }

                div.container {
                    @include clear;
                    // padding-top: 60px;
                    // padding-bottom: 40px;
                    padding: 0;
                    max-width: 1000px;
                    overflow: visible !important;

                    > div {
                        width: 100%;
                        float: left;
                        // padding: 0 20px;
                        // min-height: 140px;
                        margin-bottom: 45px;
                        // padding: 0 35px;

                        // &:nth-child(3n) {
                        //     margin-right: 0;
                        // }

                        a {
                            color: #fff;
                        }

                        p {
                            margin-bottom: 0;
                            font-size: 16px;
                            font-weight: 500;
                            color: #fff;
                            line-height: 20px;

                            &:first-child {
                                font-weight: bold;
                                color: #fff;
                                margin-bottom: 15px;

                                > a {
                                    color: #fff;
                                }
                            }

                            &.phone {
                                margin-bottom: 15px;
                                color: #fff !important;
                            }
                        }

                        a.website {
                            float: left;
                            position: relative;
                            font-size: 16px;
                            font-weight: 700;
                            color: #fff;
                            line-height: 20px;
                            text-transform: uppercase;
                            margin-top: 15px;

                            &::after {
                                content: ' ';
                                background-image: url('../images/website-white.png');
                                background-size: 13px 13px;
                                background-repeat: no-repeat;
                                position: absolute;
                                right: -23px;
                                top: 3px;
                                width: 13px;
                                height: 13px;
                            }
                        }
                    }
                }
            }
        }

        .rightBlock {
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            min-height: 520px;
            float: left;
            width: 60%;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center top;

            @include responsive(mobile) {
                display: none;
            }
        }



        // @include responsive(tablet) {
        //     div.container {
        //         > div {
        //             //padding-right: 40px;
        //
        //
        //             &:last-child {
        //                 padding-right: 0;
        //             }
        //         }
        //     }
        // }

        // @include responsive(mobile) {
        //     div.container {
        //         padding-left: 20px;
        //         padding-right: 20px;
        //
        //         > div {
        //             //width: 100%;
        //             //float: none;
        //             //padding: 0 20px;
        //             //min-height: inherit;
        //             //margin-bottom: 30px;
        //         }
        //     }
        // }

        @include responsive(small,true) {
            div.container {
                padding: 30px 30px 10px;

                > div {
                    padding-right: 0;
                    width: 50%;
                    padding-left: 0;
                    float: left;
                    padding-right: 20px;
                    margin-right: 0;

                    &:nth-child(2n) {
                        padding-right: 0;
                    }

                    p {
                        font-size: 13px;
                    }
                }
            }
        }
    }

    section.listing {

        ul {
            li {
                width: 16.66%;
                float: left;
                padding: 30px;
                opacity: 1;
                height: 16vw;
                // visibility: hidden;
                // max-height: 0;

                // &.showing {
                //     opacity: 1;
                //     visibility: visible;
                //     min-height: 244px;
                //     // margin-bottom: 40px;
                // }

                &:nth-child(13n-1) {
                    background-color: #d9d9d9;
                }

                &:nth-child(13n-2) {
                    background-color: #ebebeb;
                }

                &:nth-child(13n-3) {
                    background-color: #fff;
                }

                &:nth-child(13n-4) {
                    background-color: #d9d9d9;
                }

                &:nth-child(13n-5) {
                    background-color: #ebebeb;
                }

                &:nth-child(13n-6) {
                    background-color: #d9d9d9;
                }

                &:nth-child(13n-7) {
                    background-color: #fff;
                }

                &:nth-child(13n-8) {
                    background-color: #fff;
                }

                &:nth-child(13n-9) {
                    background-color: #ebebeb;
                }

                &:nth-child(13n-10) {
                    background-color: #ebebeb;
                }

                &:nth-child(13n-11) {
                    background-color: #d9d9d9;
                }

                &:nth-child(13n-12) {
                    background-color: #ebebeb;
                }

                &:nth-child(13n-13) {
                    background-color: #fff;
                }

                @media(max-width: 2000px) {
                    width: 14.28%;
                    height: 14vw;
                    padding: 25px;
                }

                @media(max-width: 1650px) {
                    width: 14.28%;
                    height: 16vw;
                    padding: 25px;
                }


                @media(max-width: 1550px) {
                    width: 20%;
                    height: 18vw;
                    padding: 30px;
                }

                @media(max-width: 1400px) {
                    height: 17vw;
                }

                @media(max-width: 1300px) {
                    height: 20vw;
                }

                @media(max-width: 1200px) {
                    width: 25%;
                    height: 20vw;
                }

                @media(max-width: 1150px) {
                    width: 25%;
                    height: 23vw;
                }

                @media(max-width: 1000px) {
                    width: 33.33%;
                    padding: 30px;
                    height: 33.33vw;
                }

                @media(max-width: 767px) {
                    width: 33.33%;
                    padding: 30px;
                    height: 43vw;
                }

                @media(max-width: 640px) {
                    width: 50%;
                    height: 50vw;
                }

                @media(max-width: 480px) {
                    width: 50%;
                    height: 55vw;
                    padding: 20px;
                }

                @media(max-width: 380px) {
                    width: 100%;
                    height: 55vw;
                }

                h4,
                h4 a {
                    float: left;
                    width: 100%;
                    font-size: 16px;
                    line-height: 20px;
                    letter-spacing: -1px;
                    color: $color-red;
                    text-transform: uppercase;
                }
                h4 , p {
                    float: left;
                    width: 100%;
                    font-size: 16px;
                    line-height: 20px;
                    letter-spacing: -1px;
                    font-weight: 500;
                }

                h4 {
                    > a {
                        float: left;
                        width: 100%;
                        font-weight: bold;
                    }
                }

                p {
                    float: left;
                    width: 100%;
                    margin-bottom: 0;
                    text-transform: uppercase;

                    &.phone {
                        margin-bottom: 15px;
                    }
                }

                a.website {
                    float: left;
                    position: relative;
                    font-size: 16px;
                    font-weight: 700;
                    color: #000;
                    line-height: 20px;
                    text-transform: uppercase;
                    margin-top: 15px;

                    &::after {
                        content: ' ';
                        background-image: url('../images/website-red.png');
                        background-size: 13px 13px;
                        background-repeat: no-repeat;
                        position: absolute;
                        right: -23px;
                        top: 3px;
                        width: 13px;
                        height: 13px;
                    }
                }
            }
        }



        @include responsive(tablet) {
            div.container {
                // ul {
                //     li {
                //         padding-right: 60px;
                //     }
                // }
            }
        }

        @include responsive(mobile,true) {
            /*div.container {
                padding: 60px 30px;
            }*/
        }

        @include responsive(mobile) {
            /*div.container {
                padding: 60px 0;
            }*/
        }

        @include responsive(small,true) {
            /*div.container {
                padding: 30px 0 0;

                ul {
                    li {
                        width: 50%;
                        //padding-right: 0;
                        float: left;

                        &.showing {

                            min-height: 100px;
                        }

                        &:nth-child(2n) {
                            margin-right: 0;
                        }

                        p , h4 {
                            font-size: 13px;
                        }
                    }
                }
            }*/
        }
    }

    section.region {
        div.container {
            @include clear;
            padding: 60px 0;
            max-width: 1000px;

            > div {
                display: block;
                clear: both;

                > div {
                    width: 20%;
                    float: left;
                    padding-right: 5%;

                    > a {
                        width: 100%;
                        display: block;
                    }
                }

                a {
                    font-size: 18px;
                    text-transform: uppercase;
                    display: block;
                    width: 20%;
                    float: left;
                    margin-bottom: 22px;
                    padding-right: 5%;

                    &.active {
                        text-decoration: underline;
                    }
                }
            }
        }

        @include responsive(tablet) {
            div.container {
                padding: 60px;
            }
        }

        @media(max-width: 940px) {
            display: none;
        }
        @include responsive(tablet,true) {

        }
    }

    section.pagination {
        background-color: $color-red;
        display: none;

        h2 {
            line-height: 65px;

            > a {
                color: $color-bg;
                text-transform: uppercase;
                font-size: 26px;
                font-weight: 500;
                padding-right: 30px;
                position: relative;

                &:after {
                    content: '\E00A';
                    font-family: $font-icon;
                    padding-left: 10px;
                    font-size: 12px;
                    line-height: 1;
                    position: absolute;
                    top: 11px;
                    right: 0;
                }
            }
        }

        div.container {
            @include clear;

            > a {
                display: block;
                font-size: 26px;
                font-weight: 500;
                text-align: center;
                color: $color-bg;

                &:after {
                    content: '\E00A';
                    font-family: $font-icon;
                    line-height: 70px;
                    padding-left: 10px;
                    font-size: 15px;

                }
            }

        }


        @include responsive(tablet) {

        }

        @include responsive(mobile) {

        }

        @include responsive(small,true) {
            margin-bottom: 1px;

            h2 {
                background-color: $color-red;
                line-height: 40px;
                > a {
                    font-size: 21px;
                    line-height: 40px;

                    &:after {
                        line-height: 40px;
                        font-size: 12px;
                        top: -7px;
                    }
                }
            }
        }
    }
}

/*** custom checkboxes ***/

input[type=checkbox] {
    display:none;

    @media(max-width: 940px) {
        display: none;
    }
} /* to hide the checkbox itself */

input[type=checkbox] + label:before {
  font-family: FontAwesome;
  display: inline-block;
}

input[type=checkbox] + label:before {
    content: "\f096";
    position: absolute;
    left: 0;
    display: inline-block;
} /* unchecked icon */

input[type=checkbox] + label:before {

} /* space between checkbox and label */

input[type=checkbox]:checked + label:before {
    content: "\f046";
    position: absolute;
    left: 0;
    display: inline-block;
    font-weight: normal;
} /* checked icon */

input[type=checkbox]:checked + label:before {

} /* allow space for check mark */
