@font-face {
    font-family: "Interstate";
    src: url("../fonts/Interstate-Bold.eot");
    src: url("../fonts/Interstate-Bold.eot?#iefix") format("embedded-opentype"),
         url("../fonts/Interstate-Bold.woff2") format("woff2"),
         url("../fonts/Interstate-Bold.woff") format("woff"),
         url("../fonts/Interstate-Bold.ttf") format("ttf"),
         url("../fonts/Interstate-Bold.svg#Interstate") format("svg");
    font-style: normal;
    font-weight: bold;
}
@font-face {
    font-family: "Interstate";
    src: url("../fonts/Interstate-Light.eot");
    src: url("../fonts/Interstate-Light.eot?#iefix") format("embedded-opentype"),
         url("../fonts/Interstate-Light.woff2") format("woff2"),
         url("../fonts/Interstate-Light.woff") format("woff"),
         url("../fonts/Interstate-Light.ttf") format("ttf"),
         url("../fonts/Interstate-Light.svg#Interstate") format("svg");
    font-style: normal;
    font-weight: normal;
}
@font-face {
    font-family: "Interstate";
    src: url("../fonts/Interstate-Regular.eot");
    src: url("../fonts/Interstate-Regular.eot?#iefix") format("embedded-opentype"),
         url("../fonts/Interstate-Regular.woff2") format("woff2"),
         url("../fonts/Interstate-Regular.woff") format("woff"),
         url("../fonts/Interstate-Regular.ttf") format("ttf"),
         url("../fonts/Interstate-Regular.svg#Interstate") format("svg");
    font-style: normal;
    font-weight: 500;
}
@font-face {
    font-family: "Times-BoldItalic";
    src: url("../fonts/Times BoldItalic.eot");
    src: url("../fonts/Times BoldItalic.eot?#iefix") format("embedded-opentype"),
         url("../fonts/Times BoldItalic.woff2") format("woff2"),
         url("../fonts/Times BoldItalic.woff") format("woff"),
         url("../fonts/Times BoldItalic.ttf") format("ttf"),
         url("../fonts/Times BoldItalic.svg#Times-BoldItalic") format("svg");
    font-style: normal;
    font-weight: 700;
}

@font-face {
  font-family: 'fontawesome';
  src: url('../fonts/fontawesome/fontawesome-webfont.eot?v=4.2.0');
  src: url('../fonts/fontawesome/fontawesome-webfont.eot?#iefix&v=4.2.0') format('embedded-opentype'), url('../fonts/fontawesome/fontawesome-webfont.woff?v=4.2.0') format('woff'), url('../fonts/fontawesome/fontawesome-webfont.ttf?v=4.2.0') format('truetype'), url('../fonts/fontawesome/fontawesome-webfont.svg?v=4.2.0#fontawesomeregular') format('svg');
  font-weight: normal;
  font-style: normal;
}
