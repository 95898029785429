main.page {
    section.hero {
        height: 590px;
        > div {
            position: relative;
            height: 100%;

            div.image {
                height: 100%;
                width: 100%;
            }

            div.centered {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;

                h1 {
                    font-size: 49px;
                    letter-spacing: 6px;
                    margin-bottom: 0;
                    text-transform: uppercase;
                    color: $color-bg;
                }
            }
        }

        @include responsive(tablet) {

        }

        @include responsive(mobile) {

        }

        @include responsive(small) {
            height: 340px;
            > div {
                div.centered {
                    h1 {
                        font-size: 28px;
                    }
                    center {
                        padding-left: 56px;
                        padding-right: 56px;
                    }
                }
            }
        }
    }

    section.content {
        div.container {
            @include clear;
            padding: 75px 148px 57px;

            h2 {
                text-align: center;
                margin-bottom: 60px;
            }

            hr {
                margin-bottom: 60px;
            }


            .subscribe-form {
                padding: 0 150px;

                .nf-response-msg {
                    text-align: center;
                }

                div.nf-form-cont {
                    width: 600px;
                    margin: 0 auto;

                    .ninja-forms-req-symbol {
                        display: none;
                    }


                    div.nf-error-wrap ,
                    .nf-form-fields-required {
                        display: none;
                    }

                    .nf-error-msg.nf-error-field-errors {
                        text-align: center;
                        font-size: 24px;
                        color: $color-body;

                    }

                    div.checkbox-container {
                        text-align: center;

                        div.nf-field {
                            display: inline-block;
                            margin: 0 auto;
                        }

                        div.nf-field-label {
                            display: inline-block;
                            padding-left: 10px;
                            margin-top: 0;
                            label {
                                line-height: 30px;
                            }
                        }

                        div.nf-field-element {
                            width: 30px;
                            height: 30px;
                            float: left;

                            input {
                                opacity: 0;
                                display: block !important;
                            }

                            &:after {
                                @include transition(all,600ms,ease);
                                content: '';
                                width: 30px;
                                height: 30px;
                                position: absolute;
                                top: 0;
                                left: 0;
                                border: 2px solid $color-body;
                                cursor: pointer;
                                z-index: 9998;
                                display: block;
                            }

                            &:before {
                                content: '\E00B';
                                font-family: $font-icon;
                                font-size: 20px;
                                line-height: 30px;
                                position: absolute;
                                top: 0;
                                left: 0;
                                cursor: pointer;
                                display: none;
                            }
                        }
                    }

                    .checkbox-wrap {
                        &.active {
                            div.nf-field-element {
                                &:after {
                                    background-color: $color-body;
                                }
                            }
                        }
                    }

                    div.submit-container {
                        padding-top: 30px;
                    }

                    .html-container {
                        p {
                            text-align: center;
                            font-size: 20px;
                            font-weight: bold;
                            margin-bottom: 0;
                            padding-top: 20px;
                        }
                    }

                    label {
                        font-size: 21px;
                        font-weight: 400;
                    }

                    input ,
                    textarea {
                        border: 2px solid $color-body;
                        background-color: #ebebeb;
                        height: 57px;
                        line-height: 57px;
                        padding-left: 20px;
                        font-size: 27px;
                        font-weight: normal;

                        @include placeholder() {
                            color: $color-body;
                            font-size: 27px;
                            font-weight: 400;
                            line-height: 57px;
                        }

                        &[type='button'] {
                            height: 68px;
                            display: block;
                            border: 4px solid $color-red;
                            color: $color-red;
                            background-color: transparent;
                            padding: 0 105px;
                            text-transform: uppercase;
                            font-weight: bold;
                            font-size: 35px;
                            margin: 0 auto;

                            &:hover {
                                background-color: $color-red;
                            }
                        }
                    }

                    textarea {
                        padding-top: 10px;
                        padding-bottom: 10px;
                        height: 300px;
                        line-height: 1.3;
                    }
                }
            }


        }
        @include responsive(tablet) {
            div.container {
                .subscribe-form {
                    padding: 0 95px;

                    div.nf-form-cont {
                        width: 100%;
                    }
                }
            }
        }

        @include responsive(mobile,true) {
            div.container {
                .subscribe-form {
                    padding: 0 0;

                    div.nf-form-cont {
                        width: 100%;
                    }
                }
            }
        }

        @include responsive(small,true) {
            div.container {
                padding: 30px 30px 24px;

            >  h2 {
                    font-size: 21px;
                    margin-bottom: 30px;

                    &:first-child {
                        padding: 0 0;
                    }
                }

                hr {
                    margin: 30px auto;
                }

                div.subscribe-form {
                    padding: 0;

                    h2 {
                        font-size: 21px;
                        margin-bottom: 30px;
                    }

                    div.nf-form-cont {
                        width: 100%;

                        label {
                            font-size: 15px;
                        }

                        div.checkbox-container {
                            div.nf-field-label {
                                label {
                                    padding-left: 0;
                                    line-height: 15px;
                                }
                            }

                            div.nf-field-element {
                                &:after {
                                    width: 20px;
                                    height: 20px;
                                    border: 3px solid $color-body;
                                }
                            }
                            div.field-wrap {
                                //width: 100%;
                            }
                        }

                        div.submit-container {
                            padding-top: 0;
                        }

                        input {
                            font-size: 16px;
                            height: 45px;
                            line-height: 45px;
                            border: 3px solid $color-body;
                            padding-left: 12px;

                            &[type="text"] ,
                            &[type="email"] {
                                @include placeholder() {
                                    font-size: 16px;
                                    line-height: 45px;
                                }
                            }

                            &[type="button"] {
                                width: 235px;
                                padding: 0;
                                height: 45px;
                                line-height: 40px;
                                font-size: 21px;
                            }
                        }

                        textarea {
                            height: 200px;
                            font-size: 16px;
                            padding-left: 12px;
                            line-height: 1.1;
                            padding-top: 0;
                            border: 3px solid $color-body;

                            @include placeholder() {
                                font-size: 16px;
                            }
                        }

                        .nf-field-container {
                            margin-bottom: 12px !important;
                        }

                        .html-container {
                            p {
                                font-size: 15px;
                                padding-top: 12px;
                            }
                        }
                    }
                }
            }

        }

        @include responsive(small) {
            div.container {
                div.subscribe-form {
                    div.nf-form-cont {
                        div.checkbox-container {

                            div.nf-field-label {
                                padding-left: 0;
                            }
                            div.nf-field-element {
                                width: 20px;
                                height: 20px;
                            }
                        }
                    }
                }
            }

        }

        @media(max-width: 385px) {
            div.container {
                div.subscribe-form {
                    div.nf-form-cont {
                        div.checkbox-container {

                            div.nf-field-label {
                                padding-left: 0;
                                line-height: 15px;
                                label {
                                    font-size: 12px;
                                    line-height: 15px;
                                }
                            }
                            div.nf-field-element {
                                width: 15px;
                                height: 15px;

                                &:after {
                                    width: 15px;
                                    height: 15px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
