// FORMS > INPUT
$input-height: 35px;
$input-padding: 0 17px 0 17px;
$input-lineheight: 35px;
$input-bg: none;
$input-border: none;
$input-radius: 0;
$input-font-size: 18px;
$input-font-weight: normal;
$input-align: left;
$input-color: $color-body; // FORMS > SELECT
$select-icon: '\E004';
$select-icon-top: 13px;
$select-icon-right: 20px;
$select-font-size: 14px;
$select-padding-start-webkit: 15px;
$select-padding-start: 10px; // FORMS > TEXTAREA
$textarea-height: 102px;
$textarea-padding: 2px 15px;
$textarea-lineheight: 1;
$textarea-resize: none; // FORMS > BUTTON
$button-height: 35px;
$button-width: auto;
$button-padding: 0 24px;
$button-lineheight: 35px;
$button-bg: $color-red;
$button-border: $input-border;
$button-radius: 0;
$button-font-size: 13px;
$button-font-weight: bold;
$button-font-family: $font-alt;
$button-transform: $input-transform;
$button-align: center;
$button-color: $input-color;
$button-spacing: 2px;
$button-submit-height: 35px;
$button-submit-width: 140px;
$button-submit-lineheight: 35px; // FORMS > CHECKBOX
$checkbox-icon: '\E00E';
$checkbox-icon-active: '\E00F';
$checkbox-padding-left: 8px;
$checkbox-icon-top: 1px;
$checkbox-icon-left: -15px;
$checkbox-font-size: 20px;
$checkbox-width: 15px;
$checkbox-height: $checkbox-width;

input:focus,
select:focus,
textarea:focus {
	outline: 0;
	background-color: none !important;
}

.ninja-forms-form-wrap,
body {
	input[type=email],
	input[type=number],
	input[type=password],
	input[type=tel],
	input[type=text],
	select,
	textarea {
		outline: 0;
		height: 35px;
		width: 100%;
		line-height: 35px;
		background: $input-bg;
		border: $input-border;
		font-family: $font-main;
		font-size: $input-font-size;
		font-weight: $input-font-weight;
		text-align: $input-align;
		background-color: $color-bg;
		color: $color-body;
		padding: 0 0 0 10px;
		margin: 0;
		@include transition(all, 400ms, ease);
		border-radius: 0;
	}

	textarea {
		height: $textarea-height;
		padding: $textarea-padding $input-padding;
		line-height: $textarea-lineheight;
		resize: $textarea-resize;
	}

	select::-ms-expand {
		display: none;
	}

	select {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		-webkit-padding-start: 10px;
		-moz-padding-start: 15px;
	}

	input[type="checkbox"],
	input[type="radio"] {
		height: auto;
	}

	input[type="button"]::-moz-focus-inner,
	input[type="file"] > input[type="button"]::-moz-focus-inner,
	input[type="reset"]::-moz-focus-inner,
	input[type="submit"]::-moz-focus-inner {
		padding: 0;
		border: 0;
	}

	.button,
	button,
	input[type="button"],
	input[type="submit"] {
		display: inline-block;
		outline: 0;
		text-decoration: none;
		cursor: pointer;
		height: $button-height;
		padding: $button-padding;
		background: $button-bg;
		border: 2px solid #a3a3a3;
		color: #a3a3a3;
		font-size: $button-font-size;
		font-weight: $button-font-weight;
		text-align: $button-align;
		overflow: hidden;
		-webkit-appearance: none;
		-webkit-border-radius: 0;
		border-radius: $button-radius;
		@include transition(all, 400ms, ease);

		&:hover {
			background-color: $color-body;
			border-color: $color-body;
			color: #fff !important;
			text-decoration: none;
		}
	}

	button,
	input[type="button"],
	input[type="submit"] {
		height: $button-submit-height;
		width: $button-submit-width;
		border: $input-border;
		font-weight: normal;
		padding: 0 25px;
		line-height: $button-submit-lineheight - 4;
	}

	button,
	input[type="submit"] {
		height: 60px;
		width: 130px;
		background-color: transparent;
		border: none;
		font-size: 20px;
		font-weight: bold;
		padding: 0;
		color: #2a2a2a;
		border: $input-border;
		border-left: none;
		letter-spacing: 0;
		line-height: $button-submit-lineheight - 4;
	}

	.button {
		width: $button-width;
		line-height: $button-lineheight;
	}
}

::-webkit-input-placeholder {
	color: $color-placeholder;
	font-family: $font-main;
	font-weight: normal;
	line-height: 35px;
	font-size: 18px;
	opacity: 1;
}

::-moz-placeholder {
	color: $color-placeholder;
	font-family: $font-main;
	font-weight: normal;
	line-height: 35px;
	font-size: 18px;
	opacity: 1;
}

:-ms-input-placeholder {
	color: $color-placeholder;
	font-family: $font-main;
	font-weight: normal;
	line-height: 35px;
	font-size: 18px;
	opacity: 1;
}
