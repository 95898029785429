footer {
    background-color: #d8d8d8;

    div.container {
        @include clear;
        padding-top: 80px;
        padding-bottom: 128px;

        div.footer_menu {
            @include clear;
            padding-bottom: 30px;
            border-bottom: 1px solid $color-body;

            a.logo {
                float: left;
                padding-top: 2px;

                img {
                    width: 180px;
                }
            }

            nav.main_menu {
                display: block;
                float: left;
                padding-left: 160px;
                width: 50%;

                &.mobile_version {
                    display: none;
                }

                ul {
                    li {
                        width: 50%;
                        float: left;
                        margin-bottom: 16px;
                        line-height: 1;

                        &:nth-child(2n+1) {
                            float: left;
                        }

                        a {
                            font-size: 21px;
                            font-weight: 500;
                            text-transform: uppercase;

                            &:hover {
                                color: $color-red;
                            }
                        }
                    }
                }
            }

            div.newsletter {
                display: inline-block;
                float: right;

                p {
                    font-size: 20px;
                    font-weight: bold;
                    line-height: 1;
                    //margin-bottom: 33px;
                    text-transform: uppercase;
                }

                .nf-form-fields-required,
                .ninja-forms-req-symbol {
                    display: none;
                }


                nf-field {
                    float: left;
                    &:first-child {
                        width: 87%;

                    }

                    &:last-child {
                        width: 13%;

                    }
                }

                .email-container {
                    width: 100%;
                    @include placeholder() {
                        font-size: 16px;
                        font-weight: normal;
                        color: red;
                    }

                }

                .nf-field-container {
                    margin-bottom: 0;
                }

                .nf-form-layout {
                    margin-top: 33px;
                }

                .nf-response-msg {
                    margin: -15px 0;
                    padding: 0;

                    span {
                        font-family: $font-main !important;
                        font-size: 16px !important;
                    }
                }

                div.submit-container {
                    @include transition(all,500ms,ease);
                    position: relative;
                    width: 45px;
                    overflow: hidden;
                    cursor: pointer;

                    &:before {
                        content: '\E005';
                        font-family: $font-icon;
                        position: absolute;
                        z-index: 9;
                        left: 0;
                        right: 0;
                        line-height: 35px;
                        font-size: 21px;
                        color: $color-bg;
                        text-align: center;
                        margin: 0 auto;
                        pointer-events: none;
                    }

                    &:hover {
                        &:before {
                            color: $color-red;
                        }

                        input {
                            background-color: $color-bg;
                        }
                    }
                }
                input[type="button"] {
                    @include transition(all,500ms,ease);
                    position: relative;
                    width: 100%;
                    cursor: pointer;

                    &:hover {

                    }

                }
            }
        }

        div.social_links {
            @include clear;

            ul {
                display: table;
                margin: 0 auto;
                padding-top: 50px;

                li {
                    float: left;
                    position: relative;
                    padding-left: 64px;
                    margin-right: 60px;

                    a {
                        font-size: 22px;
                        line-height: 43px;
                        font-weight: 500;
                        text-transform: uppercase;

                        &:before {
                            font-family: $font-icon;
                            font-size: 43px;
                            position: absolute;
                            left: 0;
                            top: 0;
                        }

                        &:hover {
                            color: $color-red;

                            &:before {
                                color: $color-body;
                            }
                        }
                    }

                    &:last-child {
                        padding-left: 78px;
                    }

                    &.facebook {
                        a {
                            &:before {
                                content: '\E001';
                            }
                        }
                    }

                    &.instagram {
                        a {
                            &:before {
                                content: '\E007';
                            }
                        }
                    }

                    &.youtube {
                        a {
                            &:before {
                                content: '\E004';
                            }
                        }
                    }

                    &:last-child {
                        margin-right: 0;
                    }


                }
            }
        }
    }

    @media(max-width: 1100px) {
        div.container {
            div.footer_menu {
                nav.main_menu {
                    width: 40%;
                    padding-left: 80px;
                }
            }
        }
    }

    @include responsive(tablet) {
        div.container {
            div.footer_menu {
                nav.main_menu {
                    padding-left: 50px;
                }
            }
        }
    }

    @include responsive(mobile,true) {
        div.container {
            padding: 45px 0 0;

            div.footer_menu {
                padding: 0 30px 20px;
                a.logo {
                    width: 100%;
                    float: none;
                    margin: 0 auto;

                    img {
                        width: 160px;
                        margin: 0 auto;
                    }
                }

                div.newsletter {
                    padding-top: 40px;
                    padding-bottom: 22px;
                    display: table;
                    float: none;
                    margin: 0 auto;
                }

                nav.main_menu {
                    display: none;
                    width: 100%;
                    float: none;
                    padding-left: 0;

                    &.mobile_version {
                        display: inline-block;
                    }


                    ul {
                        li {
                            width: 100%;
                            float: none;
                            text-align: center;
                        }
                    }
                }
            }

            div.social_links {
                ul {
                    padding-top: 35px;

                    li {
                        float: left;
                        display: flex;
                        margin-left: 20px;
                        padding-left: 0;
                        a {
                             color: transparent;
                             visibility: hidden;
                             max-width: 0;
                            &:before {
                                color: $color-body;
                                visibility: visible !important;
                            }
                        }
                    }
                }
            }
        }
    }

    @include responsive(mobile) {
        div.container {
            padding: 45px 0 0;

            div.footer_menu {
                padding: 0 30px 20px;
                a.logo {
                    width: 100%;
                    float: none;
                    margin: 0 auto;

                    img {
                        width: 160px;
                        margin: 0 auto;
                    }
                }

                div.newsletter {
                    padding-top: 40px;
                    padding-bottom: 22px;
                    display: table;
                    float: none;
                    margin: 0 auto;

                    nf-field {
                        &:first-child {
                            width: 84%;
                        }

                        &:last-child {
                            width: 16%;
                        }
                    }

                    input {
                        height: 55px;
                        line-height: 55px;

                        @include placeholder() {
                            line-height: 55px;
                        }
                    }
                    .email-container {
                        @include placeholder() {
                            font-size: 55px;
                        }

                    }
                    div.submit-container {
                        width: 100%;

                        &:before {
                            line-height: 55px;
                            font-size: 18px;
                        }
                    }

                    p {
                        text-align: center;
                    }
                }

                nav.main_menu {
                    display: none;
                    width: 100%;
                    float: none;
                    padding-left: 0;

                    &.mobile_version {
                        display: inline-block;
                    }


                    ul {
                        li {
                            width: 100%;
                            float: none;
                            text-align: center;
                        }
                    }
                }
            }

            div.social_links {
                ul {
                    padding-top: 35px;

                    li {
                        float: left;
                        display: flex;
                        margin-left: 20px;
                        padding-left: 0;
                        a {
                             color: transparent;
                             visibility: hidden;
                             max-width: 0;
                            &:before {
                                color: $color-body;
                                visibility: visible !important;
                            }
                        }
                    }
                }
            }
        }
    }

    @include responsive(small) {
        div.container {
            div.footer_menu {
                div.newsletter {
                    display: block;
                    width: 100%;

                    .email-container {
                        width: 100%;
                        float: left;
                    }

                    div.submit-container {
                        width: 100%;
                    }
                }
            }
        }
    }


}

.nf-field-element {


}
