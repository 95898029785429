section.profiles {
    background-color: $color-body;

    div.container {
        @include clear;
        padding-top: 85px;
        padding-bottom: 85px;

        ul {
            li {
                @include transition(all,1000ms,ease);
                position: relative;
                width: calc(100% / 3 - 7px);
                height: 283px;
                float: left;
                margin-right: 10px;
                margin-bottom: 10px;

                > div.image {
                    display: none;
                }

                //filter: grayscale(100%);
                &:hover {
                    @include gray(100%);

                    h4 {
                        &:last-child {
                            opacity: 0;
                            visibility: hidden;
                            max-height: 0;
                        }

                        &:first-child {
                            @include transition(all,600ms,ease);
                            opacity: 1;
                            visibility: visible;
                            max-height: 1000px;
                        }
                    }
                }

                h4 {
                    //@include transition(all,600ms,ease);
                    font-weight: 500;
                    letter-spacing: 1px;
                    color: $color-bg;
                    text-transform: uppercase;

                    line-height: 1;
                    left: 0;
                    right: 0;
                    opacity: 1;
                    visibility: visible;


                    &:first-child {
                        opacity: 0;
                        visibility: hidden;
                        max-height: 0;
                    }


                }

                a {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    z-index: 999;
                }

                &:nth-child(3n) {
                    margin-right: 0;
                }
            }
        }
    }
    @include responsive(tablet) {

    }

    @include responsive(mobile) {
        div.container {
            ul {
                li {
                    width: calc(50% - 7px);

                    &:nth-child(3n) {
                        margin-right: 9px;
                    }

                    &:nth-child(2n) {
                        margin-right: 0;
                    }


                }
            }
        }
    }

    @include responsive(small,true) {
        background-color: transparent;
        div.container {
            padding: 0;

            ul {
                li {
                    width: 100%;
                    height: auto;
                    margin-right: 0;
                    margin-bottom: 0;
                    background-image: none !important;

                    &:hover {
                        h4 {
                            &:first-child ,
                            &:last-child {
                                opacity: 1;
                                visibility: visible;
                                max-height: 1000px;
                            }
                        }
                    }

                    > div.image {
                        display: block;
                        position: relative;
                        height: 60vw;
                        width: 100%;

                        > a {
                            width: 100%;
                            height: 100%;
                            position: absolute;

                            &:after {
                                font-size: 60px;
                                line-height: 60vw;
                            }
                        }
                    }

                    > a {
                        bottom: 0;
                        height: calc(100% - 60vw);
                    }

                    div.centered {
                        position: relative;

                        center {
                            padding: 30px 30px 25px;
                            position: relative;

                            h4 {
                                color: $color-body;
                                font-size: 27px;
                                font-weight: 500;
                                text-align: left;

                                &:first-child {
                                    opacity: 1;
                                    visibility: visible;
                                    max-height: 1000px;
                                    font-weight: bold;
                                }
                            }


                        }
                        &:after {
                            content: '\E005';
                            font-family: $font-icon;
                            font-size: 36px;
                            color: #95989a;
                            height: 100%;
                            display: table-cell;
                            vertical-align: middle;
                            padding-right: 30px;

                        }
                    }

                }
            }
        }
    }
}

@include responsive(small) {
    main.profile ,
    main.video ,
    main.stockists ,
    main.page {
        section.profiles {
            div.container {
                ul {
                    li {
                        background-color: #363636;
                        margin-bottom: 1px;

                        &:last-child {
                            margin-bottom: 0;
                        }

                        &.active {
                            @include gray(0);
                        }

                        > div.image {
                            display: none;
                        }

                        > a {
                            height: 100%;
                        }

                        div.centered {
                            center {
                                padding-bottom: 30px;
                                h4 {
                                    color: $color-bg;
                                    font-size: 21px;

                                    &:first-child {
                                        padding-bottom: 3px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
