section.fits_categories {
    @include clear;
    ul {
        li {
            position: relative;
            width: 100%;
            height: 282px;
            margin-bottom: 10px;

            > div.image {
                @include transition(all,1000ms,ease);
                height: 100%;
                width: 100%;
            }

            &:last-child {
                margin-bottom: 0;
            }

            a {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                z-index: 99;

                &.mobile {
                    display: none;
                }
            }

            div.container {
                position: absolute;
                z-index: 9;
                top: 0;
                padding-left: 142px;
                display: table;
                width: 100%;
                height: 100%;
                left: 0;
                right: 0;

                p {
                    width: 100%;
                    height: 100%;
                    display: table-cell;
                    vertical-align: middle;
                    font-size: 48px;
                    font-weight: bold;
                    letter-spacing: 4px;
                    text-transform: uppercase;
                    color: $color-bg;

                    @include responsive(mobile) {
                        display: none;
                    }
                }
            }

            &:hover {
                > div.image {
                    @include scale(1.05);
                }

            }
        }
    }

    @include responsive(tablet) {

    }

    @include responsive(mobile) {
        ul {
            li {
                height: 20vw;
                div.container {
                    p {
                        font-size: 38px;
                    }
                }
            }
        }
    }

    @include responsive(small,true) {
        ul {
            li {
                height: 20vw;
                div.container {
                    padding-left: 70px;
                    p {
                        font-size: 32px;
                    }
                }

                &:hover {
                    > div.image {
                        @include scale(1);
                    }
                }
            }
        }
    }
    @include responsive(small,true) {
        display: none;

        ul {
            li {
                background-image: none !important;
                background-color: #363636;
                height: auto;
                margin-bottom: 3px;

                a {
                    display: none;

                    &.mobile {
                        display: block;
                    }
                }

                div.container {
                    padding: 40px 0;

                    p {
                        font-size: 28px;
                        text-align: center;

                        &:after {
                            content: '\E005';
                            font-family: $font-icon;
                            font-size: 27px;
                            color: #8d9091;
                            position: absolute;
                            right: 30px;
                        }
                    }
                }
            }
        }
    }
}
