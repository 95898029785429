div[data-carousel] {
	opacity: 0;
	@include transition(opacity, 800ms, ease);

	&.active {
		opacity: 1;
	}
}

div.owl-nav {
	position: absolute;
	top: 45%;
	right: 0;
	pointer-events: none;

	div.owl-next,
	div.owl-prev {
		display: inline-block;
		cursor: pointer;
		pointer-events: all;

		&:after {
			font-family: $font-icon;
			font-weight: normal;
			font-size: 57px;
			color: #95989a;
			@include responsive(small) {
				font-size: 28px;
			}
		}
	}

	div.owl-prev {
		float: left;
		@include transition(all , 600ms , ease);
		margin-left: -50px;

		&:after {
			content: '\E008';
			cursor: pointer;
		}

		&:hover {
			@include transition(all , 600ms , ease);
			margin-left: -60px;
		}

		&:after {
			content: "\E008";
		}

		@media(max-width: 1300px) {
			margin-left: -40px;
		}
	}

	div.owl-next {
		float: right;
		@include transition(all , 600ms , ease);
		margin-right: -50px;

		&:after {
			content: '\E005';
			cursor: pointer;
		}

		&:hover {
			@include transition(all , 600ms , ease);
			margin-right: -60px;
		}

		&:after {
			content: "\E005";
		}
		@media(max-width: 1300px) {
			margin-right: -40px;
		}
	}
}

div.owl-dots {
	position: absolute;
	bottom: 30px;
	left: 0;
	right: 0;
	text-align: center;
	display: none !important;

	div.owl-dot {
		display: inline-block;
		height: 12px;
		width: 12px;
		border-radius: 50%;
		margin: 0 4px;
		background: #a3a3a3;

		&.active {
			background-color: #2a2a2a;
		}
	}
}
