main.video {

    section.category {
        display: none;

        @include responsive(small,true) {
            display: block;

            > section {
                display: block;
            }
        }
    }

    section.slider {
        section.hero {
            background-color: $color-body;
            height: 720px;
            div.container {
                height: 100%;
                overflow: inherit;

                .owl-carousel {
                    width: 100%;
                    height: 100%;



                    div {
                        width: 100%;
                        height: 100%;
                        position: relative;
                        z-index: 999;

                        > a {
                            display: none;
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            top: 0;
                            left: 0;
                            z-index: 99999;
                        }

                        iframe {
                            width: 100%;
                            height: 100%;
                        }

                        &.owl-nav {
                            position: absolute;
                            height: auto;

                            > div {
                                width: auto;
                            }
                        }
                    }
                }
            }

            @media(max-width: 1300px) {
                div.container {
                    padding: 0 60px;
                }
            }

            @include responsive(small,true) {
                display: none;
            }
        }

        section.content {
            div.container {
                overflow: hidden;
                @include clear;

                div.content {
                    position: relative;
                    min-height: 300px;

                    > div {
                        text-align: center;
                        display: none;

                        > div {
                            &.share {
                                clear: both;
                            }

                            &:last-child {
                                clear: both;
                                padding: 20px 143px 0;

                                h2 {
                                    text-transform: uppercase;
                                    letter-spacing: 2px;

                                    &:first-child {
                                        margin-bottom: 0;
                                    }

                                    &.title {
                                        margin-bottom: 30px;
                                    }

                                    &:nth-child(2) {
                                        font-weight: 500;
                                        letter-spacing: 0;
                                        margin-bottom: 65px;
                                    }
                                }
                                p {
                                    margin-bottom: 60px;
                                }

                                ul {
                                    display: table;
                                    margin: 0 auto 60px;

                                    li {
                                        display: inline-block;
                                        float: left;
                                        margin-right: 10px;

                                        &:last-child {
                                            margin-right: 0;
                                        }

                                        a {
                                            font-size: 34px;
                                            padding: 14px 30px;
                                            letter-spacing: 2px;
                                            display: inline-block;
                                        }
                                    }
                                }
                            }
                        }



                        &.active {
                            display: block;
                        }
                    }
                }
            }

            @include responsive(mobile) {
                div.container {
                    div.content {

                        > div {
                            > div {
                                &:last-child {
                                    padding-top: 10px;
                                }
                            }
                        }
                    }
                }
            }
        }

        @include responsive(tablet) {

        }

        @include responsive(mobile) {

        }

        @include responsive(small,true) {
            display: none;
        }
    }

    section.videos {
        div.container {
            @include clear;
            padding-top: 10px;
            padding-bottom: 60px;

            > ul {
                margin: 0 auto;
                width: 100%;

                li {
                    float: left;
                    width: calc(100% / 3 - 34px);
                    margin-right: 50px;

                    &:nth-child(3n) {
                        margin-right: 0;
                    }

                    &:nth-child(3n+1) {
                        clear: both;
                    }

                    &.active {
                        div.video {
                            div.image {
                                @include gray(100%);
                            }
                            a.play {
                                cursor: default;
                                pointer-events: none;
                            }
                        }
                    }

                    div.video {
                        width: 100%;
                        height: 205px;

                        a.play {
                            &:after {
                                font-size: 80px;
                                line-height: 205px;
                                z-index: 999;
                            }

                            &:hover + div.image {
                                @include gray(100%);
                            }
                        }

                        a.mobile {
                            display: none;
                        }

                        > div {
                            &:after {
                                display: none;
                            }
                        }
                    }

                    div.category {
                        padding: 25px 0;
                        position: relative;

                        > a {
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            top: 0;
                        }
                        p {
                            margin-bottom: 0;
                            text-transform: uppercase;
                            text-align: center;
                            font-weight: bold;
                            line-height: 1;

                            &:nth-child(2) {
                                color: $color-red;
                            }
                        }

                        div.title {
                            display: table;
                            margin: 0 auto;
                            p {
                                color: $color-body;
                                display: block;
                                width: 100%;

                                &:first-child {
                                    padding-bottom: 5px;
                                }
                                //float: left;
                            }
                        }
                    }

                    div.content {
                        display: none;
                    }

                    div.text {
                        padding: 25px 0;
                        position: relative;

                        > a {
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            top: 0;
                        }
                        p {
                            font-size: 20px;
                            margin-bottom: 0;
                            text-transform: uppercase;
                            text-align: center;
                            font-weight: bold;
                            line-height: 1;

                            &:nth-child(2){
                                margin-bottom: 5px;
                            }

                            &:last-child {
                                font-weight: 500;
                            }
                        }
                    }
                }
            }

            div.mobile {
                display: none;
            }
        }


        @include responsive(tablet) {

        }

        @include responsive(mobile) {
            @include clear;

            div.container {
                @include clear;

                > ul {
                    margin: 0 -5px;

                    @include clear;

                    li {
                        // width: calc(100%/3 - 20px);
                        // margin: 0 10px;
                        // float: none;

                        @include clear;

                        div.video {
                            a.play {
                                &:after {
                                    font-size: 68px;
                                }
                            }
                        }
                    }
                }
            }
        }

        @include responsive(small,true) {
            div.container {
                padding: 0;
                margin-top: 0;

                > ul {
                    margin: 0 auto;

                    li {
                        width: 100%;
                        float: none;
                        margin: 0 auto;

                        div.video {
                            height: 55vw;

                            a.play {
                                display: none;
                                &:after {
                                    line-height: 55vw;
                                    font-size: 68px;
                                }
                            }

                            a.mobile {
                                display: block;
                                position: absolute;
                                width: 100%;
                                height: 100%;
                                top: 0;
                            }
                        }

                        div.category {
                            padding: 35px 45px 30px;

                            p {
                                font-size: 23px;

                                &:nth-child(2) {
                                    display: none;
                                }

                                &.title {
                                    font-weight: 500;
                                    padding-top: 5px;
                                }
                            }

                            div.title {
                                p {
                                    display: block;
                                    width: 100%;
                                }
                            }
                        }
                        div.content {
                            padding: 0 45px;
                            display: block;

                            p {
                                text-align: center;
                            }
                        }
                    }
                }

                div.mobile {
                    display: block;
                    padding: 0 57px 35px;
                    border-top: 1px solid $color-body;

                    div.share {
                        padding-top: 35px;
                        margin: 0 auto 12px;
                        float: none;
                        width: 100%;
                        display: block;

                        ul {
                            width: 100%;

                            li {
                                float: left;
                                width: calc(50% - 5px);
                                margin-right: 5px;

                                &:last-child {
                                    margin-right: 0;
                                }
                            }
                        }
                    }

                    div.link {
                        clear: both;
                        width: 100%;

                        > a {
                            width: 100%;
                            display: block;
                        }
                    }
                }
            }
        }
    }
}

body.video {
    section.videos {
        div.container {
            padding-bottom: 35px;

            @include responsive(small,true) {
                margin-bottom: 0;
            }
        }

    }

    section.slider {
        section.content {
            div.container {
                div.content {
                    > div {
                        > div {

                            &.share {
                                position: absolute;
                                right: 0;
                                padding-top: 75px;

                                @include responsive(mobile) {
                                    padding-top: 30px;
                                }
                            }
                            &:last-child {
                                padding-top: 65px;
                                @include responsive(mobile) {
                                    padding-left: 100px;
                                    padding-right: 100px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
